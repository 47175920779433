<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition"> <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title">영업년월</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "yearMonth"
                    :format="DATE_FORMAT_YYYY_MM"
                    :notClear="true"
                    depth="Year"
                    v-model="searchFilterOptions.yearMonth"
                    type="lookup-condition"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET"  :ignore="isPopupOpened" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button">
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{ gridField.title }}</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind="initGrid"
                  @actionComplete="gridComplete"
                  @headerCellInfo="gridHeaderCellInfo"
                  @queryCellInfo="gridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>

import InputDate from "@/components/common/datetime/InputDate.vue";
import {DATE_FORMAT_YYYY_MM, DATE_FORMAT_YYYY_MM_DD, getFormattedDate,} from "@/utils/date";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "MonthlySalesIncreasStatus",
  components: {ejsGridWrapper, ErpButton, InputDate},
  async created() {

  },
  mounted() {
    this.initData();
  },
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      searchFilterOptions: {
        yearMonth: null,
      },
      gridField: {
        title: "월별매출증감현황",
        dataSource: [],
      },
      weekday: [],
    };
  },
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
    initGrid() {
      return {
        columns: [
          {field: 'yearMonthName',textAlign: 'center', width: 80,  visible: true,   allowEditing : false,  type: 'string', headerText: '구분',},
          {field: 'attrbName',    textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '구분',},
          {field: 'yearMonth',    textAlign: 'center', width: 0,   visible: false,  allowEditing : false,  type: 'string', headerText: '구분',},
          {field: 'attrb',        textAlign: 'center', width: 0,   visible: false,  allowEditing : false,  type: 'string', headerText: '구분',},
          {field: 'total',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '소계', isNumericType: true,  },
          {field: 'day01',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '1일',    isNumericType: true,  },
          {field: 'day02',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '2일',    isNumericType: true,  },
          {field: 'day03',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '3일',    isNumericType: true,  },
          {field: 'day04',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '4일',    isNumericType: true,  },
          {field: 'day05',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '5일',    isNumericType: true,  },
          {field: 'day06',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '6일',    isNumericType: true,  },
          {field: 'day07',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '7일',    isNumericType: true,  },
          {field: 'day08',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '8일',    isNumericType: true,  },
          {field: 'day09',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '9일',    isNumericType: true,  },
          {field: 'day10',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '10일',   isNumericType: true,  },
          {field: 'day11',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '11일',   isNumericType: true,  },
          {field: 'day12',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '12일',   isNumericType: true,  },
          {field: 'day13',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '13일',   isNumericType: true,  },
          {field: 'day14',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '14일',   isNumericType: true,  },
          {field: 'day15',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '15일',   isNumericType: true,  },
          {field: 'day16',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '16일',   isNumericType: true,  },
          {field: 'day17',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '17일',   isNumericType: true,  },
          {field: 'day18',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '18일',   isNumericType: true,  },
          {field: 'day19',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '19일',   isNumericType: true,  },
          {field: 'day20',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '20일',   isNumericType: true,  },
          {field: 'day21',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '21일',   isNumericType: true,  },
          {field: 'day22',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '22일',   isNumericType: true,  },
          {field: 'day23',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '23일',   isNumericType: true,  },
          {field: 'day24',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '24일',   isNumericType: true,  },
          {field: 'day25',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '25일',   isNumericType: true,  },
          {field: 'day26',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '26일',   isNumericType: true,  },
          {field: 'day27',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '27일',   isNumericType: true,  },
          {field: 'day28',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '28일',   isNumericType: true,  },
          {field: 'day29',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '29일',   isNumericType: true,  },
          {field: 'day30',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '30일',   isNumericType: true,  },
          {field: 'day31',        textAlign: 'right',  width: 80,  visible: true,   allowEditing : false,  type: 'number', headerText: '31일',   isNumericType: true,  },
        ],
        provides: [Aggregate,ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
      };
    },
  },
  methods: {
    //초기화
    async initData() {
      this.searchFilterOptions.yearMonth = moment().format(DATE_FORMAT_YYYY_MM);
     },
    //region ============================ API ==========================================
    async apiGetList(){
       const data   = await GolfErpAPI.fetchMonthlySalesIncreasStatus({
         yearMonth: this.searchFilterOptions.yearMonth,
         attrbIdx: 2
       });
      if(data.length==0) {
        this.gridField.dataSource = [];
        return;
      }
      let dataSoruce=[];
      let dataItem={};
      const yearMonth = this.searchFilterOptions.yearMonth.replace('-','');
      const preYearMM = moment(this.searchFilterOptions.yearMonth, "YYYY-MM").add(-1,"year").format("YYYYMM");
      //기본 내장객
      dataSoruce.push(this.onCalculatorRowItem(data,yearMonth,"VISIT_PER"));
      //기본 골프
      dataSoruce.push(this.onCalculatorRowItem(data,yearMonth,"GOLF"));
      //기본 식음료
      dataSoruce.push(this.onCalculatorRowItem(data,yearMonth,"FOOD"));
      //기본 소계
      dataSoruce.push(this.onCalculatorSubTotalRowItem(data,yearMonth,"VISIT_PER"));

      //작년 내장객
      dataSoruce.push(this.onCalculatorRowItem(data,preYearMM,"VISIT_PER"));
      //작년 골프
      dataSoruce.push(this.onCalculatorRowItem(data,preYearMM,"GOLF"));
      //작년 식음료
      dataSoruce.push(this.onCalculatorRowItem(data,preYearMM,"FOOD"));
      //작년 소계
      dataSoruce.push(this.onCalculatorSubTotalRowItem(data,preYearMM,"VISIT_PER"));


      //증감 내장객
      dataSoruce.push(this.onCalculatorIncreasRowItem(dataSoruce,yearMonth,preYearMM,"VISIT_PER"));
      //증감 골프매출
      dataSoruce.push(this.onCalculatorIncreasRowItem(dataSoruce,yearMonth,preYearMM,"GOLF"));
      //증감 식음료
      dataSoruce.push(this.onCalculatorIncreasRowItem(dataSoruce,yearMonth,preYearMM,"FOOD"));

      this.gridField.dataSource =dataSoruce;
      // 달력에 없는 날짜 컬럼은 가리자... 이상하게 setTimeout function으로 감싸야 적용됨..
      setTimeout(() => {
        let lastDay = moment(getFormattedDate(this.searchFilterOptions.yearMonth)).endOf('month').format('DD');
        for(lastDay++;lastDay<=31;lastDay++){
          this.$refs.grid.hideColumns(`${lastDay}일`);
        }
      }, 0);
    },
    onCalculatorRowItem(data,yearMonth,attrb){
      let dataItem={};
      let total = 0;
      this.weekday.forEach((item) => dataItem[`day${item.bsnDate.substring(8)}`] = 0);
      data.filter((it)=> it.yearMonth==yearMonth && it.attrb ==attrb).forEach((item)=>{
        dataItem.yearMonthName = item.yearMonth.substring(0,4)+"년" +  item.yearMonth.substring(4)+"월";
        dataItem.yearMonth = item.yearMonth;
        dataItem.attrbName = item.attrbName;
        dataItem.attrb     = item.attrb;
        if(attrb!="VISIT_PER") {
          item.result = Math.round(item.result / 1000);
        }
        dataItem[`day${item.day}`] = item.result;
        total += item.result;
      });
      dataItem.total = total;
      return dataItem;
    },
    onCalculatorSubTotalRowItem(data,yearMonth,attrb){
      let dataItem={};
      this.weekday.forEach((item) => dataItem[`day${item.bsnDate.substring(8)}`] = 0);
      dataItem.yearMonth = yearMonth;
      dataItem.attrbName ="계";
      this.weekday.forEach((item) => {
        dataItem[`day${item.bsnDate.substring(8)}`] =
            data.filter((it) => it.yearMonth == yearMonth && it.attrb != attrb && it.day ==item.bsnDate.substring(8)).map(data => data.result).reduce((acc, cur) => acc + cur, 0);
      });
      dataItem.total = data.filter((it) => it.yearMonth == yearMonth && it.attrb != attrb).map(data => data.result).reduce((acc, cur) => acc + cur, 0);
      return dataItem;
    },
    onCalculatorIncreasRowItem(dataSoruce,yearMonth,preYearMM,attrb){
      let dataItem={};
      this.weekday.forEach((item) => dataItem[`day${item.bsnDate.substring(8)}`] = 0);
      dataItem.yearMonth = "증감";
      dataItem.yearMonthName = "증감";
      this.weekday.forEach((item) => {
        let yearIncreas = 0;
        let preYearIncreas = 0;
        if (dataSoruce.find((it) => it.yearMonth == yearMonth && it.attrb == attrb )) {
          yearIncreas =  dataSoruce.find((it) => it.yearMonth == yearMonth && it.attrb == attrb )[`day${item.bsnDate.substring(8)}`];
        }
        if (dataSoruce.find((it) => it.yearMonth == preYearMM && it.attrb == attrb )) {
          preYearIncreas =  dataSoruce.find((it) => it.yearMonth == preYearMM && it.attrb == attrb )[`day${item.bsnDate.substring(8)}`];
        }
        const increase  = yearIncreas -  preYearIncreas;
        dataItem[`day${item.bsnDate.substring(8)}`] = isNaN(increase)?0:increase;
      });

      let yearIncreas = 0 ;
      let preYearIncreas = 0;
      if (dataSoruce.find((it) => it.yearMonth == yearMonth && it.attrb == attrb)) {
        yearIncreas =  dataSoruce.find((it) => it.yearMonth == yearMonth && it.attrb == attrb ).total;
      }
      if (dataSoruce.find((it) => it.yearMonth == preYearMM && it.attrb == attrb)) {
        preYearIncreas =  dataSoruce.find((it) => it.yearMonth == preYearMM && it.attrb == attrb).total;
      }
      let increase  = yearIncreas -  preYearIncreas;

      dataItem.attrbName = dataSoruce.find((it) => it.yearMonth == yearMonth && it.attrb == attrb)?.attrbName;
      dataItem.total = isNaN(increase)?0:increase;
      return dataItem;
    },
    //endregion ========================================================================
    //region ============================ Button Click ==========================================
    async onClickSearch() {
      const calenderInfos = await GolfErpAPI.fetchCalenderInfos(
          moment(getFormattedDate(this.searchFilterOptions.yearMonth)).startOf('month').format(DATE_FORMAT_YYYY_MM_DD),
          moment(getFormattedDate(this.searchFilterOptions.yearMonth)).endOf('month').format(DATE_FORMAT_YYYY_MM_DD)
      );
      this.weekday = [];
      calenderInfos.forEach((calenderInfo) => {
        this.weekday.push({
          bsnDate: calenderInfo.bsnDate,
          bsnCode: calenderInfo.bsnCode,
          dwCode: calenderInfo.dwCode,
        });
      });
      await this.apiGetList();
    },
    onClickExcel(){
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //endregion ========================================================================
    //region ============================ 그리드 ==========================================
    //그리드 조회 완료시(카운트)
    gridComplete() {
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //그리드 헤더 이미지적용
    gridHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.weekday.filter((item)=>
            item.bsnDate.substring(8)== field.substring(3) && item.bsnCode == 'WEEKEND'
      ).length>0){
        node.querySelector(".e-headercelldiv").style.background = '#D9B59B';
      }else{
         // node.querySelector(".e-headercelldiv").style.background = '#BDC3D3';
      }
      if (field === "yearMonthName") {
        args.node.colSpan = 2;
      } else if (field === "attrbName") {
        args.cell.isSpanned = true;
      }
    },
    //그리드 셀 스타일 적용
    gridQueryCellInfo(args) {
      const {cell, column: {field, type, headerText}, data,} = args;

      if(field=="yearMonthName") {
        args.rowSpan = 4;
      }
      if(data.yearMonth== this.searchFilterOptions.yearMonth.replace('-','')) {
        cell.style.color = "#FF0000";
      }
      if(data.yearMonth== "증감" && (field === "total" || field.startsWith("day"))) {
        cell.style.color = "#0000FF";
        if (data[field] < 0 && type !== null && type.toLowerCase() === "number" ) {
          cell.style.color = "#0000ff";
          cell.innerText = "▼ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
          data[field] = "▼ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
        } else if (data[field] > 0) {
          cell.style.color = "#ff0000";
          cell.innerText = "▲ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
          data[field] = "▲ ".concat(numberWithCommas(Math.abs(data[field]).toString()));
        }
      }
      if(data.attrbName=="계") {
        cell.style.backgroundColor = "#dae5ba";
        cell.style.borderBottom = "1px solid";
      }
      if(data._no == this.$refs.grid.getBatchCurrentViewRecords().length){
        cell.style.borderBottom = "1px solid";
      }
      if ( field === "yearMonthName" ) {
        cell.style.borderBottom = '1px solid';
      }
    },
    //endregion ========================================================================
  },

};
</script>